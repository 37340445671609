












import { Component, Vue } from 'vue-property-decorator'

/**
 * A generic error page, rendered when something has gone wrong.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<ErrorView>({
  name: 'ErrorView',
  components: {
    UiContainer: () => import(
      /* webpackChunkName: "atoms/UiContainer" */
      '@/shared/modules/src/components/atoms/UiContainer/UiContainer.vue'
    ),
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '@/shared/modules/src/components/atoms/UiHeading/UiHeading.vue'
    )
  },

  metaInfo () {
    return {
      title: this.$t('modules.root.views.ErrorView.meta.title') as string
    }
  }
})
export class ErrorView extends Vue {}

export default ErrorView
